import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { FaCloudUploadAlt } from 'react-icons/fa';

import { mediaService } from 'services';
import { getCMSImageUrl } from 'utils/getImageUrl';
import FileUploader from 'app/components/FileUploader';
import { throwErrorActions } from 'store/slices/throwError';
import { ReactComponent as FaClose } from 'images/faClose.svg';

const Image = ({ handleChange, name, value, isContentBlock }) => {
  const dispatch = useDispatch();

  const onChange = async file => {
    try {
      const fd = new FormData();
      fd.set('files', file);
      const {
        data: { ids },
      } = await mediaService.uploadImage({ file: fd, purpose: 'cms' });
      const { data } = await mediaService.getImage(ids[0]);
      const image = getCMSImageUrl(data);
      handleChange({ [name]: { url: image }, isImage: true });
      dispatch(throwErrorActions.updateSuccess('Image has been successfully uploaded!'));
    } catch (error) {
      dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
    }
  };

  return (
    <div className="image-block">
      {value?.url ? (
        <div
          className="image"
          style={{
            position: 'relative',
          }}
        >
          <img
            src={value.url}
            alt={value.name}
            style={{ ...(isContentBlock && { height: '190px', objectFit: 'cover' }) }}
          />
          <div className="icon-wrapper" onClick={() => handleChange({ [name]: { url: '' } })}>
            <FaClose />
          </div>
        </div>
      ) : (
        <FileUploader
          width="100%"
          height="290px"
          isSingleImage
          placeholder={
            <div className="image-placeholder">
              <FaCloudUploadAlt size="30" />
              <div>Upload Image</div>
            </div>
          }
          onChange={([{ file }]) => onChange(file)}
        />
      )}
    </div>
  );
};

export default memo(Image);
