import { makeStyles } from '@material-ui/core';

const blockSizePerCount = {
  1: 800,
  2: 395,
  3: 260,
};

export const useStyles = makeStyles({
  container: {
    border: '2px dashed #d1d5db',
    padding: '35px 20px 20px 20px',
    borderRadius: '5px',
    position: 'relative',
    width: ({ columnCount, customWidth }) =>
      `${customWidth || blockSizePerCount[columnCount]}px !important`,
    minHeight: '120px',
    marginBottom: '20px',
    '&.flex': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  addButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlButtons: {
    backgroundColor: '#fff',

    position: 'absolute',
    alignItems: 'center',
    top: '-20px',
    right: '20px',
    display: 'flex',
    gap: '0.5rem',
  },
  controlButton: {
    color: '#DADEDE',
    cursor: 'pointer',
  },
  deleteButton: {
    color: '#F50157',
    width: '30px',
    margin: '2px',
    cursor: 'pointer',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #DADEDE',
  },
});
