export const CATEGORIES = 'categories';
export const PRICING = 'pricing';
export const LABEL = 'label';
export const FILTERS = 'filters';
export const LOCATION = 'location';
export const CITY = 'city';

export const IMAGE_TOP = 'imageTop';
export const IMAGE_LEFT = 'imageLeft';
export const IMAGE_RIGHT = 'imageRight';

export const DIVIDER = 'divider';
export const ADVERTISEMENT = 'advertisement';

export const SHOW_ADS = 'Show Ads';
export const DONT_SHOW_ADS = "Don't show Ads";

export const singleSelectOptions = [LABEL, LOCATION, CITY];

export const replaceUrlStructureData = data => {
  if (!data) return;

  const {
    type,
    term,
    label_ids,
    category_id,
    is_search: isSearch,
    is_nearby: isNearby,
    page_content: seoInfo,
    status_code: statusCode,
    is_valid_path: isValidUrl,
    province_code: provinceCode,
    is_available_now: isAvailableNow,
    selected_settlement: selectedCity,
    filters_hash: selectedFiltersHash,
    selected_province: selectedProvince,
    is_valid_path_structure: isValidPath,
  } = data;

  return {
    type,
    term,
    isNearby,
    isSearch,
    label_ids,
    isValidUrl,
    statusCode,
    isValidPath,
    category_id,
    provinceCode,
    selectedCity,
    isAvailableNow,
    selectedProvince,
    selectedFiltersHash,
    ...seoInfo,
  };
};

const filtersMapping = {
  events: ['event_period', 'event_type', 'gender', 'age', 'pricing', 'ethnicity'],
  vacancies: ['work_type', 'working_hours'],
  gay_bi: [
    'gender',
    'age',
    'ethnicity',
    'body_type',
    'height',
    'hair_color',
    'cup_size',
    'with_photos',
    'penis_length',
    'pricing',
    'languages',
    'availability',
  ],
  sex_items: ['with_photos'],
  misc: ['with_photos'],
  payment_in_kind: [
    'gender',
    'with_photos',
    'age',
    'ethnicity',
    'body_type',
    'height',
    'hair_color',
    'cup_size',
    'penis_length',
    'languages',
    'availability',
  ],
  sm: [
    'sex_options',
    'with_photos',
    'age',
    'ethnicity',
    'body_type',
    'height',
    'hair_color',
    'cup_size',
    'pricing',
    'languages',
    'availability',
  ],
};

export const getFilters = (data, key, filters) => {
  const selectedCategory = data.category?.value;
  const isCategoryMapped = selectedCategory && filtersMapping[selectedCategory];

  const filteredFilters = isCategoryMapped
    ? [...filtersMapping[selectedCategory], LOCATION, CITY, LABEL]
    : Object.keys(filters);

  return filteredFilters
    .filter(filterKey => {
      const isCurrentKey = data[key]?.label === filterKey;
      const isOppositeKey =
        (data[key]?.label === 'city' && filterKey === 'location') ||
        (data[key]?.label === 'location' && filterKey === 'city');
      return filterKey !== 'categories' && !isCurrentKey && !isOppositeKey;
    })
    .map(filterKey => ({
      label: filterKey,
      value: filterKey,
    }));
};

export const validateTrees = (enTree, nlTree) => {
  const deepCompare = (enNode, nlNode) => {
    if (Array.isArray(enNode) && Array.isArray(nlNode)) {
      return (
        enNode.length === nlNode.length &&
        enNode.every((item, index) => deepCompare(item, nlNode[index]))
      );
    } else if (typeof enNode === 'object' && typeof nlNode === 'object') {
      const enKeys = Object.keys(enNode);
      const nlKeys = Object.keys(nlNode);
      return (
        enKeys.length === nlKeys.length &&
        enKeys.every(key => nlKeys.includes(key) && deepCompare(enNode[key], nlNode[key]))
      );
    } else {
      return enNode === nlNode;
    }
  };

  return deepCompare(enTree, nlTree);
};

export const buttonOptions = [
  {
    label: 'CTA',
    value: 'cta',
  },
  {
    label: 'Primary Button',
    value: 'primary',
  },
  {
    label: 'Secondary Button',
    value: 'secondary',
  },
];

const advertisementMainOption = {
  label: 'Advertisement',
  value: ADVERTISEMENT,
};

export const advertisementLayoutOptions = [
  { label: 'Image Top', value: IMAGE_TOP },
  { label: 'Image Left', value: IMAGE_LEFT },
  { label: 'Image Right', value: IMAGE_RIGHT },
];

const advertisementOption = {
  ...advertisementMainOption,
  subMenu: advertisementLayoutOptions,
};

export const contentOptions = [
  { label: 'Title', value: 'title' },
  { label: 'Text', value: 'text' },
  { label: 'Image', value: 'image' },
  advertisementOption,
  {
    label: 'Button',
    value: 'button',
    subMenu: buttonOptions,
  },
  { label: 'Divider Line', value: 'divider' },
];

export const categoryOption = [{ label: 'Category', value: 'category' }];

export const adDisplayOptions = [
  { label: SHOW_ADS, value: SHOW_ADS },
  { label: DONT_SHOW_ADS, value: DONT_SHOW_ADS },
];

export const contentPositionOptions = [
  { label: 'Above Content', value: 'Above Content' },
  { label: 'Below Content', value: 'Below Content' },
];

export const defaultButtonValues = {
  target: '_self',
  url: '',
  text: '',
};

const defaultImageValues = {
  url: '',
  button: null,
};

const defaultAdvertisementValues = {
  title: '',
  reference: '',
  description: '',
  button: null,
  image: defaultImageValues,
};

export const defaultContentBlockValues = {
  text: {
    value: '',
    button: null,
  },
  title: '',
  description: '',
  divider: '<hr />',
  image: defaultImageValues,
  button: defaultButtonValues,
  advertisement: defaultAdvertisementValues,
};

export const pricingOptions = [{ label: 'Quickie', value: 'quickie' }];

export const getSelectOptions = (options, keysToExclude = []) =>
  options.map(option => {
    const filteredOption = Object.keys(option)
      .filter(key => !keysToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = option[key];
        return obj;
      }, {});

    return {
      ...filteredOption,
      value: filteredOption.label || filteredOption.name,
      label: filteredOption.label || filteredOption.name,
    };
  });

export const updateState = (state, updates) => {
  return {
    ...state,
    ...Object.keys(updates).reduce((acc, key) => {
      if (typeof updates[key] === 'object' && !Array.isArray(updates[key])) {
        acc[key] = {
          ...state[key],
          ...updateState(state[key] || {}, updates[key]),
        };
      } else {
        acc[key] = updates[key];
      }
      return acc;
    }, {}),
  };
};

function deepMerge(target, source) {
  for (const key in source) {
    if (source[key] instanceof File) {
      target[key] = source[key];
    } else if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
      target[key] = target[key] || {};
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}

export const changeContentState = ({
  type,
  move,
  index,
  content,
  rowIndex,
  languages,
  columnIndex,
  contentBlock,
}) => {
  const mutatedData = structuredClone(content);

  if (type === 'autocomplete') {
    const [currentLanguage] = languages;
    const secondLanguage = currentLanguage === 'en' ? 'nl' : 'en';
    mutatedData[currentLanguage] = mutatedData[secondLanguage];

    return mutatedData;
  }

  const newContent = languages.reduce((acc, language) => {
    acc[language] = structuredClone(content[language] || []);

    const languageContent = acc[language];

    switch (type) {
      case 'add':
        languageContent.push(contentBlock);
        break;

      case 'addNewBlock':
        languageContent[rowIndex][columnIndex].push(contentBlock);
        break;

      case 'move':
        const currentColumn = acc[language][rowIndex];
        const targetIndex = move === 'up' ? rowIndex - 1 : rowIndex + 1;
        const targetColumn = acc[language][targetIndex];

        acc[language][targetIndex] = currentColumn;
        acc[language][rowIndex] = targetColumn;
        break;

      case 'delete':
        languageContent.splice(rowIndex, 1);
        break;

      case 'nestedDelete':
        languageContent[rowIndex][columnIndex].splice(index, 1);
        break;

      case 'update':
        if (!languageContent[rowIndex]) {
          languageContent[rowIndex] = [];
        }

        languageContent[rowIndex][columnIndex][index] = deepMerge(
          { ...(languageContent[rowIndex][columnIndex][index] || {}) },
          contentBlock,
        );

        break;

      default:
        break;
    }

    return acc;
  }, mutatedData);

  return newContent;
};
