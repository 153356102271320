import { Children, cloneElement, createElement, useRef, useState } from 'react';
import Menu from '@material-ui/core/Menu';

const Dropdown = ({
  menu,
  trigger,
  minWidth,
  keepOpen: keepOpenGlobal,
  isOpen: controlledIsOpen,
  onOpen: onControlledOpen,
}) => {
  const ref = useRef(null);
  const [isInternalOpen, setInternalOpen] = useState(null);

  const isOpen = controlledIsOpen || isInternalOpen;

  const handleOpen = event => {
    event.stopPropagation();

    if (menu.length) {
      onControlledOpen
        ? onControlledOpen(event.currentTarget)
        : setInternalOpen(event.currentTarget);
    }
  };

  const handleForceClose = () => {
    onControlledOpen ? onControlledOpen(null) : setInternalOpen(null);
  };

  const handleClose = event => {
    event.stopPropagation();

    if (
      (ref.current && ref.current.contains(event.target)) ||
      event.target.dataset?.node === 'parentNode'
    ) {
      return;
    }

    handleForceClose();
  };

  const renderMenu = (menuItem, index) => {
    const { keepOpen: keepOpenLocal, ...props } = menuItem.props;

    const extraProps = {};

    if (props.menu) {
      extraProps.parentMenuOpen = isOpen;
    }

    return createElement(menuItem.type, {
      ...props,
      key: index,
      ...extraProps,
      'data-node': menuItem.props.menu ? 'parentNode' : '',
      onClick: event => {
        event.stopPropagation();

        if (!keepOpenGlobal && !keepOpenLocal) {
          handleClose(event);
        }

        if (menuItem.props.onClick) {
          menuItem.props.onClick(event);
        }
      },
      children: props.menu ? Children.map(props.menu, renderMenu) : props.children,
    });
  };

  return (
    <>
      {cloneElement(
        <div style={{ margin: 'auto', cursor: 'pointer', width: '40px', height: '40px' }} ref={ref}>
          {trigger}
        </div>,
        {
          onClick: isOpen ? handleForceClose : handleOpen,
          ref,
        },
      )}

      <Menu
        open={!!isOpen}
        onClose={handleClose}
        anchorEl={ref.current}
        PaperProps={{ sx: { minWidth: minWidth ?? 0 } }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {Children.map(menu, renderMenu)}
      </Menu>
    </>
  );
};

export default Dropdown;
