import clsx from 'clsx';

import Text from './Text';
import Title from './Title';
import Image from './Image';
import Reference from './Reference';

import { IMAGE_TOP, IMAGE_RIGHT } from 'app/pages/SEOStatic/utils';

const Advertisement = ({ handleChange, value = {}, name, isSingleColumn }) => {
  const { image, title, description, reference, isOffline } = value;

  return (
    <div
      className={clsx('advertisement', {
        'image-top': value.variant === IMAGE_TOP,
        rowReverse: value.variant === IMAGE_RIGHT,
        flex: isSingleColumn && value.variant !== IMAGE_TOP,
      })}
    >
      <div className="full-width">
        <Image
          name="image"
          value={image}
          withButtonsBlock={false}
          handleChange={image => handleChange({ [name]: image })}
        />
      </div>
      <div className="full-width">
        <Title value={title} handleChange={value => handleChange({ [name]: value })} name="title" />
        <Text
          value={description}
          name="description"
          handleChange={value => handleChange({ [name]: value })}
        />
        <Reference
          name="reference"
          value={reference}
          isOffline={isOffline}
          handleChange={value => handleChange({ [name]: value })}
        />
      </div>
    </div>
  );
};

export default Advertisement;
