import { capitalize } from '@material-ui/core';

import TextEditor from 'app/components/Dictionary/components/TextEditor';

const TOOLBAR_OPTIONS = [
  ['italic', 'bold', 'underline'],
  [{ color: ['black', 'red', 'yellow', 'white', 'green', 'blue', 'gray'] }],
  ['link'],
];

const Text = ({ handleChange, value, name, isSingleColumn }) => {
  const isText = name === 'text';

  return (
    <div>
      <div>{capitalize(name)}</div>
      <TextEditor
        name={name}
        container={TOOLBAR_OPTIONS}
        value={isText ? value.value : value}
        setValue={({ target }) =>
          handleChange({ [target.name]: isText ? { value: target.value } : target.value })
        }
      />
    </div>
  );
};

export default Text;
