import { memo } from 'react';
import Button from '@material-ui/core/Button';
import { BsFillPlusCircleFill } from 'react-icons/bs';

import {
  contentOptions,
  ADVERTISEMENT,
  defaultContentBlockValues,
} from 'app/pages/SEOStatic/utils';
import isObject from 'utils/isObject';
import Menu from 'app/components/Menu';
import { isEmptyExcludingId } from 'utils/isEmpty';
import ContentBlock from 'app/components/ContentBlock';

import { Flags, Title, Text, Image, Divider, Advertisement, Buttons } from './components';
import uuid from 'utils/uuid';

const blockSizePerCount = {
  1: 757,
  2: 351,
  3: 216,
};

const contentItems = {
  text: Text,
  image: Image,
  title: Title,
  description: Text,
  divider: Divider,
  button: Buttons,
  advertisement: Advertisement,
};

const Content = ({ activeFlag, setActiveFlag, data: { content }, handleContentChange }) => {
  const contentKey = activeFlag.split('_')[1];

  const currentContent = content[contentKey];

  return (
    <div className="page-content">
      <Flags activeFlag={activeFlag} setActiveFlag={setActiveFlag} />
      <div>
        {currentContent.map((row, rowIndex) => (
          <div
            key={`content-${row[0][0]?.id || rowIndex}-${activeFlag}`}
            style={{ display: row.length > 1 ? 'flex' : 'block', gap: 10 }}
          >
            {row.map((column, columnIndex) => {
              const isSingleColumn = row.length === 1;

              const isLastItem = columnIndex === row.length - 1;
              return (
                <ContentBlock
                  key={columnIndex}
                  columnCount={row.length}
                  isSingleColumn
                  disabledUpButton={rowIndex < 1}
                  disabledDownButton={rowIndex === currentContent.length - 1}
                  withActions={(row.length > 1 && isLastItem) || isSingleColumn}
                  renderContentCenter={isEmptyExcludingId(column)}
                  onDelete={() =>
                    handleContentChange({
                      columnIndex,
                      rowIndex,
                      type: 'delete',
                      languages: [contentKey],
                    })
                  }
                  onMove={move =>
                    handleContentChange({
                      move,
                      columnIndex,
                      type: 'move',
                      rowIndex,
                      languages: [contentKey],
                    })
                  }
                >
                  {column.map((block, index) => {
                    const Component = contentItems[block.type];

                    return (
                      <ContentBlock
                        key={block.id}
                        columnCount={row.length}
                        isSingleColumn={isSingleColumn}
                        showOnlyDeleteButton
                        customWidth={blockSizePerCount[row.length]}
                        onDelete={() =>
                          handleContentChange({
                            columnIndex,
                            rowIndex,
                            index,
                            type: 'nestedDelete',
                            languages: [contentKey],
                          })
                        }
                      >
                        <Component
                          isContentBlock
                          name={block.type}
                          variant={block.variant}
                          value={block[block.type]}
                          columnCount={row.length}
                          isSingleColumn={isSingleColumn}
                          handleChange={contentBlock =>
                            handleContentChange({
                              languages: [contentKey],
                              columnIndex,
                              rowIndex,
                              index,
                              contentBlock,
                              type: 'update',
                            })
                          }
                        />
                      </ContentBlock>
                    );
                  })}

                  <Menu
                    handleClick={({ type, variant }) =>
                      handleContentChange({
                        columnIndex,
                        rowIndex,
                        type: 'addNewBlock',
                        languages: [contentKey],
                        contentBlock: {
                          type,
                          id: uuid(),
                          [type]: isObject(defaultContentBlockValues[type])
                            ? {
                                ...defaultContentBlockValues[type],
                                ...(variant && { variant }),
                              }
                            : defaultContentBlockValues[type],
                        },
                      })
                    }
                    trigger={<BsFillPlusCircleFill size={30} color="#28C0A3" />}
                    menu={
                      isSingleColumn
                        ? contentOptions
                        : contentOptions.filter(({ value }) => value !== ADVERTISEMENT)
                    }
                  />
                </ContentBlock>
              );
            })}
          </div>
        ))}
      </div>
      <div className="button-group">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleContentChange({
              type: 'add',
              rowIndex: 0,
              languages: [contentKey],
              contentBlock: [[]],
            });
          }}
        >
          Add 1 column block
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleContentChange({
              type: 'add',
              rowIndex: 0,
              languages: [contentKey],
              contentBlock: [[], []],
            });
          }}
        >
          Add 2 column block
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleContentChange({
              type: 'add',
              rowIndex: 0,
              languages: [contentKey],
              contentBlock: [[], [], []],
            });
          }}
        >
          Add 3 column block
        </Button>
      </div>
    </div>
  );
};

export default memo(Content);
