import { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MUIDialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { accountDetailsService } from 'services';
import InputField from 'app/components/InputField';
import DatePicker from 'app/components/Shared/DatePicker';
import { throwErrorActions } from 'store/slices/throwError';
import { ReactComponent as FaClose } from 'images/faClose.svg';

import { useDialogStyles } from './styles';

import { defaultState, discountOptions, optionsMapping, productOptions } from '../config';

const errorMapping = {
  ERR_ACCOUNT_NOT_FOUND: 'Account not found',
  ERR_COUPON_NAME_ALREADY_EXISTS: 'Coupon name already exists',
};

const ManagementDialog = ({ selectedRow, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(selectedRow || defaultState);
  const { root, dialogTitle, dialogContent } = useDialogStyles();

  const {
    code,
    status,
    end_date,
    discount,
    account_id,
    start_date,
    total_limit,
    discount_type,
    per_user_limit,
    selected_products,
    minimum_order_amount,
  } = state;

  const isEditMode = !!selectedRow;
  const title = isEditMode ? 'Edit discount code' : 'Create discount code';

  const today = new Date().setHours(0, 0, 0, 0);

  const startHelperText =
    start_date && end_date && new Date(start_date) > new Date(end_date)
      ? 'Start date cannot be later than end date'
      : start_date && new Date(start_date) < today
      ? 'Start date cannot be in the past'
      : '';

  const endDateHelperText =
    end_date && new Date(end_date) < new Date(start_date) ? 'End date cannot be in the past' : '';

  const handleCreateDiscount = async () => {
    try {
      if (!code || !start_date || !end_date || !discount) {
        dispatch(
          throwErrorActions.updateMessage({
            message: 'Please fill all required fields',
          }),
        );
        return;
      }

      const { data } = await accountDetailsService[
        isEditMode ? 'updateDiscount' : 'createDiscount'
      ](state);

      if (data) {
        dispatch(
          throwErrorActions.updateSuccess(
            `Page has been successfully ${isEditMode ? 'edited' : 'created'}!`,
          ),
        );
        handleCloseDialog(true);
      }
    } catch (e) {
      dispatch(
        throwErrorActions.updateMessage({
          message: errorMapping[e.response?.data.message] || 'Something went wrong...',
        }),
      );
    }
  };

  const handleDataChange = newData => {
    setState(prev => ({ ...prev, ...newData }));
  };

  return (
    <MUIDialog
      open
      maxWidth="lg"
      className={root}
      disableBackdropClick
      onClose={() => handleCloseDialog()}
    >
      <DialogTitle className={dialogTitle} id="alert-dialog-title">
        <div>
          <div className="title">{title}</div>
          <div className="close">
            <FaClose width="20px" height="20px" onClick={() => handleCloseDialog()} />
          </div>
        </div>
        <hr />
      </DialogTitle>
      <DialogContent className={dialogContent} style={{ minWidth: '860px' }}>
        <div className="discount-code flex gap20">
          <InputField
            required
            value={code}
            disabled={isEditMode}
            className="full-width"
            title="Discount code name"
            placeholder="Create unique name"
            inputProps={{ disabled: isEditMode }}
            handleChange={e => handleDataChange({ code: e.target.value })}
          />
          <div className="full-width" />
        </div>
        <div className="discount-code flex gap20">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selectedDate={start_date}
            error={!!startHelperText}
            helperText={startHelperText}
            className="discount-date-picker"
            disabled={isEditMode && status !== 'pending'}
            setSelectedDate={date => handleDataChange({ start_date: date })}
            label={
              <span className="select-title">
                <span>* </span>Select start date
              </span>
            }
          />

          <DatePicker
            dateFormat="yyyy-MM-dd"
            selectedDate={end_date}
            error={!!endDateHelperText}
            helperText={endDateHelperText}
            className="discount-date-picker"
            setSelectedDate={date => handleDataChange({ end_date: date })}
            label={
              <div className="select-title">
                <span>* </span>Select end date
              </div>
            }
          />
        </div>
        <div className="discount-code flex gap20">
          <div className="full-width">
            <div className="select-title">
              <span>* </span>
              Choose discount type
            </div>
            <Select
              fullWidth
              id="select"
              className="select"
              disabled={isEditMode}
              title="Choose discount type"
              value={optionsMapping[discount_type]}
              onChange={e => handleDataChange({ discount_type: optionsMapping[e.target.value] })}
            >
              {discountOptions?.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
          <InputField
            required
            title="Discount"
            value={discount || ''}
            inputProps={{ disabled: isEditMode }}
            placeholder={discount_type === 'percentage_based' ? '000 (%)' : '000 (€)'}
            className="full-width"
            handleChange={e => {
              if (isNaN(e.target.value)) return;
              handleDataChange({ discount: Number(e.target.value) });
            }}
          />
        </div>
        <div className="discount-code flex gap20">
          <InputField
            className="full-width"
            placeholder="00"
            title="Minimum order value (€)"
            value={minimum_order_amount || ''}
            handleChange={e => {
              if (isNaN(e.target.value)) return;
              handleDataChange({ minimum_order_amount: Number(e.target.value) });
            }}
          />
          <div className="full-width" />
        </div>
        <div className="discount-code flex gap20">
          <div className="full-width">
            <div className="select-title">Restrict discount codes to</div>
            <Select
              multiple
              fullWidth
              id="select"
              className="select"
              title="Restrict discount codes to"
              renderValue={selected => {
                if (selected.length === 0) {
                  return <em>Placeholder</em>;
                }

                return selected.join(', ');
              }}
              value={
                selected_products.length
                  ? selected_products.map(product => optionsMapping[product])
                  : ['Select product']
              }
              onChange={e =>
                handleDataChange({
                  selected_products: e.target.value
                    .filter(value => optionsMapping[value])
                    .map(value => optionsMapping[value]),
                })
              }
            >
              {productOptions?.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>

          <InputField
            value={account_id || ''}
            className="full-width"
            placeholder="Enter user ID"
            title="Configure the discount for"
            handleChange={e => {
              if (isNaN(e.target.value)) return;
              handleDataChange({ account_id: Number(e.target.value) });
            }}
          />
        </div>
        <div className="discount-code flex gap20">
          <InputField
            className="full-width"
            title="Limits per user"
            value={per_user_limit || ''}
            placeholder="Enter limits per user"
            handleChange={e => {
              if (isNaN(e.target.value)) return;
              handleDataChange({ per_user_limit: Number(e.target.value) });
            }}
          />
          <InputField
            className="full-width"
            title="Total usage limits"
            value={total_limit || ''}
            placeholder="Enter total usage limits"
            handleChange={e => {
              if (isNaN(e.target.value)) return;
              handleDataChange({ total_limit: Number(e.target.value) });
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button variant="contained" onClick={handleCloseDialog} color="secondary">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreateDiscount}
          disabled={startHelperText || endDateHelperText}
        >
          {isEditMode ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </MUIDialog>
  );
};

export default memo(ManagementDialog);
